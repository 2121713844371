import { Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowUturnLeftIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { Form, Link, useLocation, useRouteLoaderData } from "@remix-run/react";
import { clsx } from "clsx";
import { useState } from "react";

const TopNav: React.FC = () => {
  const data = useRouteLoaderData<{ email: string }>("root");
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  const isBookmarks = pathname === "/favourites";
  const isVisualiser = pathname.startsWith("/c");
  const [_isNavVisible, setIsNavVisible] = useState(!isHome);

  return (
    <nav
      className={clsx(
        "absolute inset-x-0 z-10",
        "flex items-center justify-end",
        "p-4 text-center text-sm text-white",
        "transition-transform",
      )}
    >
      <a
        className="mr-auto flex items-center gap-2 opacity-80 transition-opacity hover:opacity-100"
        href="https://prmf.co.uk"
      >
        <ArrowUturnLeftIcon className="h-4 w-4" />
        to prmf.co.uk
      </a>
      <div
        className={clsx(
          "absolute inset-x-0 top-0 -z-10 h-20",
          "bg-gradient-to-b from-black/50 transition-opacity duration-500",
          !isVisualiser && "opacity-0",
        )}
      />
      <Transition
        show={!isHome}
        className="flex gap-2 divide-x transition-transform"
        enterFrom="-translate-y-20"
        leaveTo="-translate-y-20"
        afterEnter={() => setIsNavVisible(true)}
        afterLeave={() => setIsNavVisible(false)}
      >
        {isBookmarks ? (
          <Link
            to={-1 as unknown as string}
            className="flex items-center gap-1 px-2"
          >
            <ArrowUturnLeftIcon className="h-4 w-4" />
            Back
          </Link>
        ) : (
          <Link to="/favourites" className="flex items-center gap-1 px-2">
            <StarIcon className="h-4 w-4" />
            Favourites
          </Link>
        )}
        <Form action="/" method="delete">
          <button className="flex items-center gap-1 px-2" type="submit">
            <b>{data?.email || "Guest"}</b>
            <ArrowLeftOnRectangleIcon className="h-4 w-4" />
          </button>
        </Form>
      </Transition>
    </nav>
  );
};

export default TopNav;
