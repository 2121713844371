import cache from "@prmf/visualizer-cache";
import TopNav from "@prmf/visualizer-components/src/TopNav";
import {
  ActionFunctionArgs,
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";
import {
  ClientActionFunction,
  ClientLoaderFunction,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  matchPath,
  useLocation,
} from "@remix-run/react";
import { getFirestore } from "firebase-admin/firestore";
import { Toaster } from "react-hot-toast";
import { session } from "./cookies.server";
import "./index.css";

export const headers: HeadersFunction = () => ({
  //https://firebase.google.com/docs/hosting/manage-cache
  "Cache-Control": "public, max-age=86400, s-max-age=86400",
});

export const links: LinksFunction = () => [
  { rel: "shortcut icon", href: "#", type: "image/x-icon" },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap",
  },
];

export const meta: MetaFunction = () => {
  return [
    { property: "og:type", content: "website" },
    { property: "og:title", content: "PRMF Kitchen Visualiser" },
    { property: "og:url", content: "https://visualizer.prmf.co.uk" },
    {
      property: "og:image",
      content: "https://visualizer.prmf.co.uk/ogimage.png",
    },
    {
      property: "og:description",
      content: "A tool to help you creating your dream kitchen",
    },
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const r: { email: string } | null = await session.parse(
    request.headers.get("Cookie"),
  );
  let bookmarks = [];
  if (r?.email) {
    const doc = await getFirestore().collection("profiles").doc(r.email).get();
    bookmarks = doc.get("bookmarks");
  }
  return { email: r?.email, bookmarks };
};

export const clientLoader: ClientLoaderFunction = async ({ serverLoader }) => {
  if (cache.get("email")) {
    return serverLoader();
  }
  return { bookmarks: cache.get("bookmarks") };
};

export const action = async ({ request }: ActionFunctionArgs) => {
  if (request.method === "POST") {
    const email = (await request.formData()).get("email");
    return redirect("/c", {
      headers: {
        "Set-Cookie": await session.serialize({ email }),
      },
    });
  }
  if (request.method === "DELETE") {
    return redirect("/", {
      headers: { "Set-Cookie": await session.serialize({ email: undefined }) },
    });
  }
  return null;
};

export const clientAction: ClientActionFunction = async ({
  request,
  serverAction,
}) => {
  const req = request.clone();
  if (req.method === "POST") {
    const email = (await req.formData()).get("email")?.toString();
    cache.set("email", email, true);
  }
  if (req.method === "DELETE") {
    cache.set("email", null, true);
  }
  return serverAction();
};

export default function App() {
  const { pathname } = useLocation();
  const isSummaryPrintView = matchPath("/p/*", pathname);
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <ScrollRestoration />
        {isSummaryPrintView ? (
          <Outlet />
        ) : (
          <div className="flex min-h-screen flex-col bg-gray-600">
            <Toaster containerClassName="text-sm" />
            <TopNav />
            <div className="flex flex-1">
              <Outlet />
            </div>
          </div>
        )}
        <Scripts />
      </body>
    </html>
  );
}
